





































































































































import { Component, Vue } from "vue-property-decorator";
import { departamento } from "@/shared/dtos/departamento";
import { MessageService } from "@/shared/services/message-service";
import departamentoModule from "@/store/modules/departamento-module";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import empresaModule from "@/store/modules/empresa-module";
@Component({
  components: { Xbtn: () => import("@/components/buttons/x-btn.vue") },
  $_veeValidate: { validator: "new" },
})
export default class DepartamentosLista extends Vue {
  public search: string = "";
  public show_dialog: boolean = false;
  public obj_departamento: departamento = new departamento({ activo: true });
  public es_edicion: boolean = false;
  public created() {
    departamentoModule.getdepartamentos();
    empresaModule.getempresas();
  }

  public get datasource() {
    return departamentoModule.departamentos;
  }
  public get empresas() {
    return empresaModule.empresas;
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      { text: "Ubicación", value: "ubicacion" },
      { text: "Proveedor", value: "empresa.nombre" },
      { text: "Activo", value: "activo" },
      { text: "", value: "acciones", align: "end" },
    ];
  }

  /**
   * editar
   */
  public editar_rw(obj: departamento) {
    this.obj_departamento = new departamento(obj);
    this.es_edicion = true;
    this.show_dialog = true;
  }

  /**
   * add_new
   */
  public add_new() {
    this.$validator.reset();
    this.show_dialog = true;
  }
  /**
   *eliminar
   */
  public eliminar(obj: departamento) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar " + obj.nombre + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          departamentoModule
            .eliminardepartamento(obj)
            .then(() => departamentoModule.getdepartamentos())
            .catch(() => {
              MessageService.toast(
                "No se ha podido eliminar, hay datos asociados a este departamento",
                MessageService.TypeWarning
              );
            });
        }
      }
    );
  }

  /**
   * cancelar
   */
  public cancelar() {
    this.obj_departamento = new departamento({ activo: true });
    this.show_dialog = false;
    this.es_edicion = false;
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.es_edicion) {
          departamentoModule.modificardepartamento(this.obj_departamento);
        } else {
          departamentoModule.guardardepartamento(this.obj_departamento);
        }
        this.cancelar();
      }
    });
  }
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
